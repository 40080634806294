import logo from '../../../assets/images/logo.png';
import './styles.scss';

const OverlayPanel = (props) => {
  return (<div className="overlay-panel">
    <div className="overlay" />
    {props.showLogo && (
      <div className="logo-wrapper">
        <img className="logo-image" alt="Reset Health" src={logo} />
      </div>
    )}
    
    <div className="overlay-content">
      {props.title && <h1 className="overlay-title">{props.title}</h1>}
      {props.children}
    </div>
  </div>);
};

export default OverlayPanel;