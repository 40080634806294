import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'rsuite/dist/styles/rsuite-default.css'
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react'

import LoginScreen from './components/Screens/LoginScreen';
import WelcomeScreen from './components/Screens/WelcomeScreen';
import ProgressScreen from './components/Screens/ProgressScreen';
import MoodScreen from './components/Screens/MoodScreen';
import JournalScreen from './components/Screens/JournalScreen';
import ThanksScreen from './components/Screens/ThanksScreen';
import './styles/App.scss';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="App">
          <Router>
            <Switch>
              <Route exact path="/" component={LoginScreen} />
              <Route exact path="/welcome" component={WelcomeScreen} />
              <Route exact path="/progress" component={ProgressScreen} />
              <Route exact path="/mood" component={MoodScreen} />
              <Route exact path="/journal" component={JournalScreen} />
              <Route exact path="/thanks" component={ThanksScreen} />
            </Switch>
          </Router>
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
