import LoginGate from '../../Elements/LoginGate';
import BackgroundImage from '../../Elements/BackgroundImage';
import OverlayPanel from '../../Elements/OverlayPanel';

import './styles.scss';

const ThanksScreen = (props) => {
  return ( 
    <>
      <LoginGate loginPath="/" />
      <BackgroundImage>
        <OverlayPanel
          showLogo={true}
          title="Thanks!">
          <div className="welcome-inner">
            <div className="welcome-text-wrapper">
              <p>Thank you for logging your data today. The more information we have about your progress, the easier it is for us to make sure you have the support and the structure you need to successfully reset.</p>
            </div>
          </div>
        </OverlayPanel>
      </BackgroundImage>
    </>
  )
}

export default ThanksScreen;