import backgroundImageFile from '../../../assets/images/bg-fullscreen.jpg';
import './styles.scss';

const BackgroundImage = (props) => {
  return (
    <div className="background-image-wrapper">
      <div className="background-image-container">
        <img alt="Reset Health wallpaper" className="background-image" src={backgroundImageFile} />
        {props.overlay && <div className="background-image-overlay" />}
      </div>
        { props.children }
    </div>
  );
}

export default BackgroundImage;