import { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { NumberPicker } from 'react-widgets'
import SplitScreen from '../../Elements/SplitScreen';
import background from '../../../assets/images/bg-split-1.jpg';
import './styles.scss';


const ProgressScreen = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const nextScreenHandler = useCallback(() => {
    history.push('/mood')
  }, [history])

  return(<SplitScreen title="Progress update" backgroundImage={background} buttonImage="next" buttonText="Next" buttonAction={nextScreenHandler}>
    <div className="row-wrapper">
      <p className="quesion-label">What is your current weight?</p>
      <div className="field-wrapper">
        <NumberPicker defaultValue={75} min={1} max={200} containerClassName="number-picker-wrapper" /><span className="number-picker-units">kg</span>
      </div>
    </div>

    <div className="row-wrapper">
      <p className="quesion-label">What is your blood pressure?</p>
      <div className="field-wrapper">
        <div className="number-picker-label-wrapper">
          <label>Systolic (top number)</label>
          <NumberPicker defaultValue={120} min={1} max={200} containerClassName="number-picker-wrapper" />
        </div>
        <span className="bp-divider">/</span>
        <div className="number-picker-label-wrapper">
          <label>Diostolic (bottom number)</label>
          <NumberPicker defaultValue={80} min={1} max={200} containerClassName="number-picker-wrapper" />
        </div>
      </div>
    </div>
    
    <div className="row-wrapper">
      <p className="quesion-label">What is your current waist measurement?</p>
      <div className="field-wrapper">
        <NumberPicker defaultValue={75} min={1} max={200} containerClassName="number-picker-wrapper" /><span className="number-picker-units">cm</span>
      </div>
    </div>
  </SplitScreen>);
}

export default ProgressScreen;