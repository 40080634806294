import { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Rating from 'react-rating';
import SplitScreen from '../../Elements/SplitScreen';
import background from '../../../assets/images/bg-split-1.jpg';
import './styles.scss';


const MoodScreen = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const nextScreenHandler = useCallback(() => {
    history.push('/journal')
  }, [history])

  return(<SplitScreen title="How's it going?" backgroundImage={background} buttonImage="next" buttonText="Next" buttonAction={nextScreenHandler}>
    <div className="row-wrapper">
      <p className="quesion-label">How are your energy levels?</p>
      <div className="field-wrapper">
        <Rating fullSymbol={{display: 'inline-block', borderRadius: '50%', border: '5px double white', width: '30px', height: '30px', backgroundColor: '#90F9DE'}} />
      </div>

      <p className="quesion-label">How is your mood?</p>
      <div className="field-wrapper">
        <Rating fullSymbol={{display: 'inline-block', borderRadius: '50%', border: '5px double white', width: '30px', height: '30px', backgroundColor: '#90F9DE'}} />
      </div>
    </div>


  </SplitScreen>);
}

export default MoodScreen;