import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import LoginGate from '../../Elements/LoginGate';
import BackgroundImage from '../../Elements/BackgroundImage';
import OverlayPanel from '../../Elements/OverlayPanel';

import './styles.scss';

const WelcomeScreen = (props) => {
  const history = useHistory();

  const loadNextPage = useCallback(() => history.push('/progress'), [history])

  return ( 
    <>
      <LoginGate loginPath="/" />
      <BackgroundImage>
        <OverlayPanel
          showLogo={true}
          title="Welcome to Reset Health">
          <div className="welcome-inner">
            <div className="welcome-text-wrapper">
              <p>Thank you for logging your data today. The more information we have about your progress, the easier it is for us to make sure you have the support and the structure you need to successfully reset.</p>
              <p>We’re asking you for three different types of data today, but if you aren’t able to enter everything, it’s absolutely OK to leave it blank.</p>
            </div>
            <div className="welcome-actions-wrapper">
              <button className="primary" onClick={loadNextPage}>I'm ready to enter my data</button>
            </div>
          </div>
        </OverlayPanel>
      </BackgroundImage>
    </>
  )
}

export default WelcomeScreen;