import { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Rating from 'react-rating';
import SplitScreen from '../../Elements/SplitScreen';
import background from '../../../assets/images/bg-split-1.jpg';
import './styles.scss';
  
const JournalScreen = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const nextScreenHandler = useCallback(() => {
    history.push('/thanks')
  }, [history])

  return(<SplitScreen title="How's it going?" backgroundImage={background} buttonImage="next" buttonText="Finish" buttonAction={nextScreenHandler}>
    <p className="instructions">Keeping a journal can make a real difference to your progress</p>
    <div className="textarea-row-wrapper">
      <p className="quesion-label">Add a note to your journal</p>
      <textarea className="journal-textarea"></textarea>
    </div>


  </SplitScreen>);
};

export default JournalScreen;