import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  account: {},
  loggedIn: false,
}

export const resetterSlice = createSlice({
  name: 'resetters',
  initialState,
  reducers: {
    setLoggedIn: (state, action) => {
      console.log(action);
      state.account = action.payload.account;
      state.token = action.payload.token;
      state.loggedIn = true;
    }
  }
});

export const login = (email, password) => {
  return (dispatch, getState) => {
    fetch(window.location.origin).then((response) => { // API CALL
      dispatch(resetterSlice.actions.setLoggedIn({
        account: {
          forename: 'Joe',
          surname: 'Bloggs',
          email: 'joe@bloggs.com',
          userid: 1,
        },
        token: 'API TOKEN',
      }));
    }).catch(err => {
      // HANDLE ERROR;
    })
  }
}

export const resetterSelectors = {
  loggedIn: (state) => state.resetters.loggedIn,
}

export default resetterSlice.reducer;