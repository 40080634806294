import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

// import loggersReducer from './slices/loggersSlice';
// import authReducer from './slices/userSlice';
// import videoReducer from './slices/videoSlice';
// import tagsReducer from './slices/tagsSlice';
// import languagesReducer from './slices/languagesSlice';
// import videoLogReducer from './slices/videoLogSlice';
// import messagesReducer from './slices/messagesSlice';
// import queueReducer from './slices/queueSlice';
// import firebaseReducer from './slices/firebaseSlice';
// import editReducer from './slices/editSlice';
// import timelineReducer from './slices/timelineSlice';
import resettersReducer from './slices/resetterSlice';

const reducers = combineReducers({
    resetters: resettersReducer,
});

const middleware = process.env.NODE_ENV === 'production' ? [thunk] : [thunk, logger];

const persistConfig = {
    key: 'root',
    storage,
  }

const persistedReducer = persistReducer(persistConfig, reducers)


export const store = configureStore({
    reducer: persistedReducer, 
    middleware: middleware
});

export const persistor = persistStore(store);
