import './styles.scss';
import nextImage from '../../../assets/images/button-next.png';

const buttonImages = {
  next: nextImage
};

const SplitScreen = (props) => {
  return (<div className="split-screen-wrapper">
    <div className="split-screen-content-wrapper">
      <h1 className="title">{props.title}</h1>
      <div className="split-screen-content-inner">
        {props.children}
        {props.buttonImage && props.buttonText && (<div className="button">
          <button className="split-screen-button" onClick={props.buttonAction}>
            <img alt={props.buttonImage} src={buttonImages[props.buttonImage || 'next']} /><span>{props.buttonText}</span>
          </button>
        </div>)}
      </div>
    </div>
    <div className="split-screen-background-wrapper">
      {props.backgroundImage && (
        <img alt="Background" src={props.backgroundImage} />
      )}
      <div className="split-screen-overlay" />
    </div>
  </div>);
};

export default SplitScreen;