import { useEffect } from 'react';
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { resetterSelectors } from '../../../redux/slices/resetterSlice';

const LoginGate = (props) => {
  const history = useHistory();
  const isLoggedIn = useSelector(resetterSelectors.loggedIn);
  useEffect(() => {
    console.log('LOGGED IN AT GATE', isLoggedIn);
    if(!isLoggedIn) {
      history.push(props.loginPath);
    }
  }, [isLoggedIn, history, props]);

  return (<></>);
};

export default LoginGate;